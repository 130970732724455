<template>
	<div style="text-align: center;padding: 30px 10px;">
		<van-icon name="warning" size="110" color="orange"/>
		
		<p style="margin: 20px 0 80px 0;">登录已失效，请重新登录</p>
		
		
		<van-button type="danger" size="small" round @click="login()">重新登录</van-button>
	</div>
</template>

<script>
	export default {
		name: 'nologin',
		methods: {
			login() {
				if (window.JsObject != null) {
					window.JsObject.login()
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"action": "login"})
				}
			},
		}
	}
</script>